<template>
	<Dialog :dialog="dialog">
		<template v-slot:title> {{ doUpdate ? "Update" : "Add New" }} Line Item </template>
		<template v-slot:body>
			<v-row>
				<v-col md="3" class="py-0">
					<label for="line-item-product" class="btx-label mt-3">Item Name</label>
				</v-col>
				<v-col md="9" class="py-0 mb-3">
					<AutoCompleteInput
						hide-details
						:items="productList"
						:disabled="pageLoading"
						:loading="pageLoading"
						id="line-item-product"
						item-text="label"
						item-value="value"
						placeholder="Item Name"
						v-model="line_item.product"
						append-outer-icon="mdi-plus"
						v-on:click:append-outer="manageCategoryDialog = true"
						class="mt-0"
					></AutoCompleteInput>
				</v-col>
				<v-col md="3" class="py-0">
					<label class="btx-label mt-3">Description</label>
				</v-col>
				<v-col md="9" class="py-0">
					<TextAreaInput
						hide-details
						:disabled="pageLoading"
						:loading="pageLoading"
						v-model="line_item.description"
						class="mt-0"
						:rows="1"
					></TextAreaInput>
				</v-col>
				<v-col md="3" class="py-0">
					<label class="btx-label mt-3">UOM</label>
				</v-col>
				<v-col md="9" class="py-0">
					<TextInput
						hide-details
						:disabled="pageLoading"
						:loading="pageLoading"
						v-model="line_item.uom"
						class="mt-0"
						hide-spin-buttons
					></TextInput>
				</v-col>
				<v-col md="3" class="py-0">
					<label class="btx-label mt-3">Price</label>
				</v-col>
				<v-col md="9" class="py-0">
					<TextInput
						hide-details
						:disabled="pageLoading"
						:loading="pageLoading"
						v-model="line_item.rate"
						class="mt-0"
						type="number"
						hide-spin-buttons
					></TextInput>
				</v-col>
			</v-row>
		</template>
		<template v-slot:action>
			<v-btn
				:disabled="pageLoading"
				:loading="pageLoading"
				depressed
				tile
				@click="$emit('close', true)"
			>
				Close</v-btn
			>
			<v-btn
				class="white--text"
				:disabled="pageLoading"
				:loading="pageLoading"
				depressed
				color="blue darken-4"
				tile
				@click="saveLineItems()"
			>
				{{ doUpdate ? "Update" : "Add" }}
				Line Item
			</v-btn>
		</template>
	</Dialog>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import { cloneDeep } from "lodash";
export default {
	name: "Quotation-line-items-create-update",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: Object,
			default() {
				return {};
			},
		},
		dialog: {
			type: Boolean,
			default: false,
		},
		doUpdate: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		dialog(param) {
			if (param) {
				this.initComponent();
			}
		},
	},
	data() {
		return {
			pageLoading: false,
			line_item: {
				id: null,
				uuid: null,
				product: null,
				description: null,
				quantity: 1,
				quantity_type: null,
				discount: null,
				rate: 0.0,
				uom: null,
				tax_value: null,
				tax_amount: null,
				sub_total: 0.0,
				total: 0.0,
				product_type: null,
			},
			productList: [
				{
					id: 1,
					label: "Sample Prouct",
					value: "sample product",
				},
				{
					id: 2,
					label: "Test Prouct",
					value: "test product",
				},
				{
					id: 3,
					label: "ABC Prouct",
					value: "abc product",
				},
			],
		};
	},
	methods: {
		saveLineItems() {
			this.$emit("update", this.line_item);
			this.$emit("close", true);
		},
		/* searchLineItem(){
			if(this.search_key){
				const filtered_item = this.total_line_items.filter((row)=>{
					if(row.description.includes(this.search_key)){
						return row;
					}
				})
				const shortData = [filtered_item,...this.total_line_items]

				console.log({shortData:shortData});

				this.$emit('short',shortData);
			}
		},
		editRow(event, index) {
			this.isOutside = false;
			let element = event.target;
			const _line_items = this.total_line_items.map((row) => {
				return { ...row, isEditable: false };
			});
			_line_items[index].isEditable = true;
			this.total_line_items = _line_items;
			this.$nextTick(() => {
				const inputarea = element.querySelector("input");
				const textarea = element.querySelector("textarea");
				if (inputarea) {
					inputarea.focus();
					inputarea.select();
					textarea.focus();
					textarea.select();
				}
			});
			if (element.nodeName == "INPUT") {
				element.select();
			}
			if (this.line_items.length == index + 1) {
				this.addLineItems();
			}
		},
		deleteLineItems(index) {
			if (this.total_line_items.length > 1) {
				this.total_line_items.splice(index, 1);
				this.$emit("input", this.total_line_items);
			}
		},
		addLineItems() {
			this.total_line_items.push({
				isEditable: false,
				product: null,
				description: null,
				qty: 1,
				rate: (0).toFixed(2),
				amount: (0).toFixed(2),
			});
			console.log(123);
			this.$emit("input", this.total_line_items);
		},
		updateLineItem(){
			this.$emit("input", this.total_line_items);
		},
		getSubTotal() {
			let sum = 0;
			this.total_line_items.forEach((element) => {
				sum = sum + Number(element.amount);
			});
			return sum.toFixed(2);
		},
		fixedOnBlur() {
			const _line_item = this.total_line_items.map((row) => {
				return {
					...row,
					rate: Number(row.rate).toFixed(2),
					amount: Number(row.amount).toFixed(2),
				};
			});
			this.total_line_items = _line_item;
			this.updateLineItem();
		},
		getProuctTotal() {
			const _line_item = this.total_line_items.map((row) => {
				return {
					...row,
					amount: Number(row.qty * row.rate).toFixed(2),
				};
			});
			this.total_line_items = _line_item;
		},
		updateTotal() {
			const _line_item = this.total_line_items.map((row) => {
				return {
					...row,
					rate: Number(row.amount / row.qty).toFixed(2),
				};
			});
			this.total_line_items = _line_item;
		},
		loadMoreLineItems(){
			const _this = this;
			_this.loadMoreLoading = true;
			// console.log("total_line_items",_this.total_line_items);
			let start = _this.page_limit*_this.page_number - _this.page_limit;
			let end = start + _this.page_limit;

			if(end>_this.total_line_items.length){
				end = _this.total_line_items.length;
				_this.isLoadmore= false;
			}else{
				_this.isLoadmore= true;
			}
			//console.log({start:start,end:end});
			let _items = _this.line_items;
			for(let i=start;i<end;i++){
				_items = [..._items,_this.total_line_items[start++]]
			}
			_this.line_items = _items;
			this.$nextTick(()=>{
				const current_page = _this.page_number;
				_this.page_number = current_page+1;
				_this.loadMoreLoading = false;
			})
			//console.log("line-items",_this.line_items);
		}, */
		initComponent() {
			let _this = this;
			if (_this.doUpdate) {
				_this.line_item = cloneDeep(_this.value);
			} else {
				_this.line_item = {
					id: null,
					uuid: null,
					product: null,
					description: null,
					quantity: 1,
					quantity_type: null,
					discount: null,
					rate: 0.0,
					uom: null,
					tax_value: null,
					tax_amount: null,
					sub_total: 0.0,
					total: 0.0,
					product_type: null,
				};
			}
		},
	},
	components: {
		Dialog,
		TextInput,
		TextAreaInput,
		AutoCompleteInput,
	},
	mounted() {
		this.initComponent();
	},
};
</script>
