<template>
	<div>
		<v-layout class="py-2">
			<div class="radio-btn-custom">
				<RadioInput
					v-if="false"
					hide-details
					:disabled="pageLoading"
					:loading="pageLoading"
					id="upload-type"
					v-model="upload_excel.type"
					row
					:items="[
						{ label: 'Product', value: 'inventory' },
						{ label: 'Service', value: 'service' },
					]"
				></RadioInput>
				<v-btn-toggle
					v-model="upload_excel.type"
					mandatory
					:disabled="pageLoading"
					:loading="pageLoading"
					active-class="blue darken-4 white--text"
					class="grey"
					tile
					borderless
				>
					<v-btn @click="updateProductType('inventory')" class="px-10" value="inventory">
						Product
					</v-btn>
					<v-btn class="px-10" value="service" @click="updateProductType('service')"> Service </v-btn>
				</v-btn-toggle>
				<span class="my-auto ml-5 blue--text text--darken-4" v-if="line_item_count">
					Total Line Items
					<span class="red--text fw-600" style="font-size: 18px">({{ line_item_count }})</span>
				</span>
			</div>
			<v-spacer></v-spacer>
			<v-col lg="3" md="4" sm="6" class="d-flex">
				<TextInput
					hide-details
					:disabled="pageLoading"
					:loading="pageLoading"
					v-model="search_key"
					class="mt-0"
					placeholder="Search Line Item..."
					clearable
					@click:clear="clearSearchLineItem()"
				></TextInput>
				<v-btn tile depressed color="blue darken-4 white--text" v-on:click="searchLineItem()">
					<v-icon>mdi-magnify</v-icon>
				</v-btn>
			</v-col>
		</v-layout>
		<div class="w-100 quotation-line-items">
			<table width="100%" style="table-layout: fixed">
				<thead>
					<tr>
						<th class="pa-2" width="40"></th>
						<th class="pa-2 text-center" width="40">#</th>
						<th class="pa-2" width="100%">Name</th>
						<th class="pa-2" width="100%">Description</th>
						<th class="pa-2" width="180">UOM</th>
						<th class="pa-2" width="180">Qty</th>
						<th class="pa-2 text-right" width="180">Rate</th>
						<th class="pa-2 text-right" width="180">
							<div class="d-flex align-items-center">
								<v-checkbox
									hdie-details
									v-model="showPrice"
									class="ma-0 pt-0"
									hide-details
									small
									id="show-price"
									@change="showPriceCheckboxToggle, updateLineItemOnCheckboxChange()"
									dense
									color="blue darken-4"
								></v-checkbox>
								<label class="my-auto cursor-pointer" for="show-price">
									<TooltipQuestion>
										<template v-slot:text>
											If check then all the child product price <br />
											will be show in the printed quotation
										</template>
									</TooltipQuestion>
								</label>
								<v-spacer></v-spacer>
								<span> Price </span>
							</div>
						</th>
						<th class="pa-2 text-right" width="40"></th>
					</tr>
					<tr>
						<th class="pa-2" width="40"></th>
						<th class="pa-2" width="40"></th>
						<th class="pa-2" colspan="2">
							<TextInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								v-model="free_text"
								placeholder="Type Text"
								class="mt-0"
							></TextInput>
						</th>
						<th class="pa-2">
							<SelectUnit
								v-if="false"
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								v-model="uom"
								hide-top-margin
								append-outer-icon="mdi-cog"
								placeholder="UOM"
								class="mt-0"
							>
							</SelectUnit>
						</th>
						<th class="pa-2"></th>
						<th class="pa-2"></th>
						<th class="pa-2">
							<PriceInput
								hide-details
								:disabled="pageLoading || showPrice"
								:loading="pageLoading"
								v-model.number="sub_total"
								reverse
								type="number"
								hide-spin-buttons
								placeholder="Price"
								class="mt-0"
							></PriceInput>
						</th>
						<th class="pa-2"></th>
					</tr>
				</thead>
				<!-- <tbody > -->
				<Draggable
					tag="tbody"
					v-model="line_items"
					class="draggable-group"
					handle=".draggable-drag-icon"
					v-clickoutside="outsideClicked"
				>
					<tr v-for="(row, index) in line_items" :key="index" class="">
						<td class="py-1 px-2">
							<v-icon class="draggable-drag-icon cursor-move" color="blue darken-4">mdi-drag</v-icon>
						</td>
						<td class="py-1 px-2 text-center">{{ index + 1 }}</td>
						<td
							class="py-1 px-2"
							@click="editRow($event, index)"
							:class="row.isEditable ? 'px-2' : 'px-3'"
						>
							<v-autocomplete
								v-if="row.isEditable"
								outlined
								hide-details
								:items="productList"
								:disabled="pageLoading"
								:loading="pageLoading"
								id="Product"
								:ref="`line_item_ref_${index}`"
								:placeholder="upload_excel.type == 'inventory' ? 'product' : 'service'"
								v-model="row.product"
								class="mt-0"
								v-on:change="getProductPrice(row, index)"
							>
								<template v-slot:item="data">
									<template>
										<v-list-item-content dense>
											<v-list-item-title v-html="data.item.text"></v-list-item-title>
										</v-list-item-content>
										<v-list-item-icon dense class="my-2 pl-2 pr-0">
											<v-chip
												:class="`px-1 white--text ${data.item.product_type == 'inventory' ? 'orange' : 'blue'}`"
												label
												small
											>
												<span class="text-capitalize">
													{{ data.item.product_type == "inventory" ? "product" : data.item.product_type }}
												</span>
											</v-chip>
										</v-list-item-icon>
									</template>
								</template>
							</v-autocomplete>
							<template v-else>{{ getProductName(row.product) }}</template>
						</td>
						<td
							class="py-1 px-2"
							@click="editRow($event, index)"
							:class="row.isEditable ? 'px-2' : 'px-3'"
						>
							<TextAreaInput
								v-if="row.isEditable"
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								v-model="row.description"
								:rows="1"
								class="mt-0"
								v-on:blur="updateLineItem(row, index)"
							>
							</TextAreaInput>
							<template v-else>{{ row.description }}</template>
						</td>
						<td
							class="py-1 px-2"
							@click="editRow($event, index)"
							:class="row.isEditable ? 'px-2' : 'px-3'"
						>
							<SelectUnit
								v-if="row.isEditable"
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								v-model="row.uom"
								hide-top-margin
								placeholder="UOM"
								class="mt-0"
								v-on:change="
									updateLineItem(row, index);
									changeClicked();
								"
							></SelectUnit>
							<template v-else>{{ row.uom }}</template>
						</td>
						<td
							class="py-1 px-2"
							@click="editRow($event, index)"
							:class="row.isEditable ? 'px-2' : 'px-3'"
						>
							<QuantityInput
								v-if="row.isEditable"
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								v-model.number="row.quantity"
								class="mt-0"
								type="number"
								hide-spin-buttons
								v-on:blur="getProductTotal(row, index)"
								v-on:keydown.tab="tabClickedQty(index)"
							>
							</QuantityInput>
							<template v-else>{{ row.quantity }}</template>
						</td>
						<td
							class="py-1 px-2 text-right"
							@click="editRow($event, index)"
							:class="row.isEditable ? 'px-2' : 'px-3'"
						>
							<template v-if="showPrice">
								<div class="position-relative" v-if="row.isEditable">
									<PriceInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										v-model.number="row.rate"
										reverse
										outlined
										class="mt-0"
										type="number"
										append-icon="mdi-currency-usd"
										hide-spin-buttons
										v-on:blur="getProductTotal(row, index, 'set-rate')"
										v-on:focus="getMinRate(row, index, 'show-rate')"
										v-on:keydown.tab="
											tabClicked(index);
											getProductTotal(row, index, 'set-rate');
										"
									>
									</PriceInput>
									<span class="show-discounted-rate"> Min Rate : {{ showDiscountedRate }} </span>
								</div>
								<template v-else>{{ formatMoney(row.rate) }}</template>
							</template>
						</td>
						<td
							class="py-1 px-2 text-right"
							@click="editRow($event, index)"
							:class="row.isEditable ? 'px-2' : 'px-3'"
						>
							<template v-if="showPrice">
								<!-- <PriceInput
									v-if="row.isEditable"
									hide-details
									readonly
									:disabled="pageLoading"
									:loading="pageLoading"
									v-model.number="row.total"
									reverse
									outlined
									class="mt-0"
									type="number"
									append-icon="mdi-currency-usd"
									hide-spin-buttons
									v-on:keydown.tab.prevent="tabClicked(index)"
								>
								</PriceInput> -->
								<template class="px-1">{{ formatMoney(row.total) }}</template>
							</template>
						</td>
						<td class="py-1 px-2 text-right">
							<v-icon
								color="red"
								:disabled="line_items.length == 1 ? true : false || deleteConfirmationLoading"
								class="cursor-pointer"
								@click="deleteLineItems(row, index)"
								>mdi-delete-outline</v-icon
							>
						</td>
					</tr>
				</Draggable>
				<!-- </tbody> -->
				<tfoot v-if="false">
					<tr>
						<td class="pa-2 borer-none" colspan="5">
							<v-btn @click="addLineItems()" class="white--text" depressed color="blue darken-4" tile>
								<v-icon left>mdi-plus</v-icon>
								Add Line Item
							</v-btn>
						</td>
						<td class="pa-2 borer-none text-right" colspan="2"><b>Sub Total</b></td>
						<td class="pa-2 borer-none text-right" width="180">{{ formatMoney(sub_total) }}</td>
						<td class="pa-2 borer-none text-right" width="40"></td>
					</tr>
					<tr>
						<td class="pa-2 borer-none text-right" colspan="7">
							<div class="d-flex align-items-center justify-content-end">
								<b>Discount</b>
								<div style="max-width: 80px" class="ml-2">
									<QuantityInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										v-model="discount_value"
										type="number"
										class="mt-0"
										v-on:keyup="getDiscount()"
										v-on:change="getDiscount()"
									></QuantityInput>
								</div>
								<div style="max-width: 60px" class="">
									<AutoCompleteInput
										hide-details
										:items="discountTypeList"
										:disabled="pageLoading"
										:loading="pageLoading"
										v-model="discount_value_type"
										class="mt-0 text-center"
										v-on:change="getDiscount()"
									></AutoCompleteInput>
								</div>
							</div>
						</td>
						<td class="pa-2 borer-none text-right" width="180">
							{{ formatMoney(discount_amount) }}
						</td>
						<td class="pa-2 borer-none text-right" width="40"></td>
					</tr>
					<tr>
						<td class="pa-2 borer-none text-right" colspan="7">
							<div class="d-flex align-items-center justify-content-end">
								<v-checkbox
									hdie-details
									v-model="tax_applied"
									class="ma-0 pt-0"
									hide-details
									small
									id="is-tax"
									v-on:change="getTax()"
									dense
									color="blue darken-4"
								></v-checkbox>
								<b>Tax</b>
								<div style="max-width: 60px" class="ml-2">
									<span class="fw-600" style="font-size: 16px"> {{ tax_value }} % </span>
									<AutoCompleteInput
										v-if="false"
										hide-details
										:items="taxList"
										:disabled="pageLoading"
										:loading="pageLoading"
										v-model="tax_value"
										class="mt-0 text-center"
										v-on:change="getTax()"
									>
									</AutoCompleteInput>
								</div>
							</div>
						</td>
						<td class="pa-2 borer-none text-right" width="180">{{ formatMoney(getTax()) }}</td>
						<td class="pa-2 borer-none text-right" width="40"></td>
					</tr>
					<tr>
						<td class="pa-2 borer-none text-right" colspan="7">
							<div class="d-flex align-items-center justify-content-end">
								<b>Adjustment</b>
								<div style="max-width: 100px" class="ml-2">
									<TextInput
										min="10000"
										hide-details
										:items="taxList"
										:disabled="pageLoading"
										:loading="pageLoading"
										type="number"
										v-on:keyup="getAdjustmentAmount()"
										v-on:change="getAdjustmentAmount()"
										v-model="adjustment"
										class="mt-0"
									></TextInput>
								</div>
							</div>
						</td>
						<td class="pa-2 borer-none text-right" width="180">
							{{ formatMoney(adjustment_amount) }}
						</td>
						<td class="pa-2 borer-none text-right" width="40"></td>
					</tr>
					<tr>
						<td class="pa-2 borer-none text-right" colspan="7">
							<b style="font-size: 16px">Grand Total</b>
						</td>
						<td class="pa-2 borer-none text-right" width="180">
							<b style="font-size: 16px">{{ formatMoney(getGrandTotal()) }}</b>
						</td>
						<td class="pa-2 borer-none text-right" width="40"></td>
					</tr>
				</tfoot>
			</table>
		</div>
		<div class="w-100 quotation-line-items table-footer">
			<table width="100%" style="table-layout: fixed">
				<tfoot>
					<tr>
						<td class="pa-2 borer-none" colspan="5">
							<v-btn @click="addLineItems()" class="white--text" depressed color="blue darken-4" tile>
								<v-icon left>mdi-plus</v-icon>
								Add Line Item
							</v-btn>
						</td>
						<td class="pa-2 borer-none text-right" colspan="2"><b>Sub Total</b></td>
						<td class="pa-2 borer-none text-right" width="180">{{ formatMoney(sub_total) }}</td>
						<td class="pa-2 borer-none text-right" width="40"></td>
					</tr>
					<tr>
						<td class="pa-2 borer-none text-right" colspan="7">
							<div class="d-flex align-items-center justify-content-end">
								<b>Discount</b>
								<div style="max-width: 80px" class="ml-2">
									<QuantityInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										v-model="discount_value"
										type="number"
										class="mt-0"
										v-on:keyup="getDiscount()"
										v-on:change="getDiscount()"
									></QuantityInput>
								</div>
								<div style="max-width: 70px" class="">
									<AutoCompleteInput
										hide-details
										:items="discountTypeList"
										:disabled="pageLoading"
										:loading="pageLoading"
										v-model="discount_value_type"
										class="mt-0 text-center"
										v-on:change="getDiscount()"
									></AutoCompleteInput>
								</div>
							</div>
						</td>
						<td class="pa-2 borer-none text-right" width="180">
							{{ formatMoney(discount_amount) }}
						</td>
						<td class="pa-2 borer-none text-right" width="40"></td>
					</tr>
					<tr>
						<td class="pa-2 borer-none text-right" colspan="7">
							<div class="d-flex align-items-center justify-content-end">
								<v-checkbox
									hdie-details
									v-model="tax_applied"
									class="ma-0 pt-0"
									hide-details
									small
									id="is-tax"
									v-on:change="getTax()"
									dense
									color="blue darken-4"
								></v-checkbox>
								<b>Tax</b>
								<div style="max-width: 60px" class="ml-2">
									<span class="fw-600" style="font-size: 16px"> {{ tax_value }} % </span>
									<AutoCompleteInput
										v-if="false"
										hide-details
										:items="taxList"
										:disabled="pageLoading"
										:loading="pageLoading"
										v-model="tax_value"
										class="mt-0 text-center"
										v-on:change="getTax()"
									>
									</AutoCompleteInput>
								</div>
							</div>
						</td>
						<td class="pa-2 borer-none text-right" width="180">{{ formatMoney(getTax()) }}</td>
						<td class="pa-2 borer-none text-right" width="40"></td>
					</tr>
					<tr>
						<td class="pa-2 borer-none text-right" colspan="7">
							<div class="d-flex align-items-center justify-content-end">
								<b>Adjustment</b>
								<div style="max-width: 70px" class="ml-2">
									<TextInput
										hide-details
										:items="taxList"
										:disabled="pageLoading"
										:loading="pageLoading"
										type="number"
										v-on:keyup="getAdjustmentAmount()"
										v-on:change="getAdjustmentAmount()"
										v-model="adjustment"
										class="mt-0"
									></TextInput>
								</div>
							</div>
						</td>
						<td class="pa-2 borer-none text-right" width="180">
							{{ formatMoney(adjustment_amount) }}
						</td>
						<td class="pa-2 borer-none text-right" width="40"></td>
					</tr>
					<tr>
						<td class="pa-2 borer-none text-right" colspan="7">
							<b style="font-size: 16px">Grand Total</b>
						</td>
						<td class="pa-2 borer-none text-right" width="180">
							<b style="font-size: 16px">{{ formatMoney(getGrandTotal()) }}</b>
						</td>
						<td class="pa-2 borer-none text-right" width="40"></td>
					</tr>
				</tfoot>
			</table>
		</div>
		<Dialog :dialog="importDialog" v-on:close="importDialog = false" v-if="importDialog">
			<template v-slot:title> Upload Excel File </template>
			<template v-slot:body>
				<v-row>
					<v-col md="4" class="py-0">
						<label for="upload-file" class="btx-label mt-3">Select File</label>
					</v-col>
					<v-col md="8" class="py-0">
						<FileUpload
							:disabled="pageLoading"
							:loading="pageLoading"
							id="upload-file"
							placeholder="File"
							:allow-add-more="false"
							v-model="upload_excel.file"
						></FileUpload>
						<p class="mt-2 red--text" v-if="fileError">
							{{ fileError }}
						</p>

						<!-- <v-col md="8" class="py-0 mb-3">
							<RadioInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="upload-type"
								v-model="type"
								row
								:items="[
									{ label: 'Product', value: 'product' },
									{ label: 'Service', value: 'service' },
								]"
							></RadioInput>
						</v-col> -->
						<!-- <p class="mt-4" v-if="upload_excel.type == 'inventory'">
							Sample product excel file format
							<a
								class="blue--text text--darken-4 fw-500"
								:href="$assetURL('media/product-line-item-format.xlsx')"
								target="_blank"
								download
								>download</a
							>
						</p>
						<p class="mt-4" v-if="upload_excel.type == 'service'">
							Sample service excel file format
							<a
								class="blue--text text--darken-4 fw-500"
								:href="$assetURL('media/service line-item-format.xlsx')"
								target="_blank"
								download
								>download</a
							>
						</p> -->
					</v-col>
					<v-col md="4" class="py-0 mt-3">
						<label for="upload-type" class="btx-label mt-4">Download Sample For Line Items</label>
					</v-col>
					<v-col md="8" class="py-0 mt-2 d-flex">
						<!-- <RadioInput
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							id="upload-type"
							v-model="type"
							row
							:items="[
								{ label: 'Product', value: 'inventory',disabled:true },
								{ label: 'Service', value: 'service',disabled:true },
							]"
						></RadioInput> -->
						<v-radio-group dense mandatory id="id" v-model="type" row color="blue darken-4" hide-details>
							<v-radio
								v-for="(item, index) in items"
								:disabled="item.disabled"
								:key="index"
								class="mb-2"
								color="blue darken-4"
								:label="item.label"
								:value="item.value"
							></v-radio>
						</v-radio-group>

						<a
							class="blue--text text--darken-4 fw-500 mt-6"
							style="text-decoration: underline"
							@click="getQuotationSample"
							target="_blank"
							download
							>Download Sample</a
						>
					</v-col>
					<v-col md="12">
						<div v-if="backEndError" class="mt-2 red--text">
							<div class="mt-1" v-for="(error, index) in backEndError" :key="index">
								{{ error }}
							</div>
						</div>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn
					:disabled="pageLoading"
					:loading="pageLoading"
					depressed
					tile
					@click="(importDialog = false), (backEndError = [])"
				>
					Close</v-btn
				>
				<v-btn
					class="white--text"
					:disabled="uploading"
					:loading="uploading"
					depressed
					color="blue darken-4"
					tile
					@click="uploadLineItems()"
				>
					Upload File</v-btn
				>
			</template>
		</Dialog>
		<Dialog
			:dialog="confirmationDialog"
			v-on:close="confirmationDialog = false"
			v-if="confirmationDialog"
			:dialog-width="500"
		>
			<template v-slot:title> Are you sure? </template>
			<template v-slot:body>
				<v-row>
					<v-col md="12" class="py-0">
						<p class="mt-0 red--text" style="line-height: 18px">
							If you change line item type then all previously added line items will be deleted.
							<br />
							Are you sure about deleting it?
						</p>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn :disabled="confirmationLoading" depressed tile @click="closeConfirmation()"> NO</v-btn>
				<v-btn
					class="white--text"
					:disabled="confirmationLoading"
					:loading="confirmationLoading"
					depressed
					color="blue darken-4"
					tile
					@click="clearLineItem()"
				>
					YES</v-btn
				>
			</template>
		</Dialog>
		<Dialog
			:dialog="deleteConfirmationDialog"
			v-on:close="deleteConfirmationDialog = false"
			v-if="deleteConfirmationDialog"
			:dialog-width="500"
		>
			<template v-slot:title> Are you sure? </template>
			<template v-slot:body>
				<v-row>
					<v-col md="12" class="py-0">
						<p class="mt-0" style="line-height: 18px">
							Are you sure want to delete line item?
							<br />
							Line item can not be recovered once deleted?
						</p>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn
					:disabled="deleteConfirmationLoading"
					depressed
					tile
					@click="deleteConfirmationDialog = false"
				>
					NO</v-btn
				>
				<v-btn
					:disabled="deleteConfirmationLoading"
					:loading="deleteConfirmationLoading"
					class="white--text"
					depressed
					color="blue darken-4"
					tile
					@click="yesDeleteLineItem()"
				>
					YES
				</v-btn>
			</template>
		</Dialog>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import TextInput from "@/view/components/TextInput";
import SelectUnit from "@/view/components/SelectUnit";
import Dialog from "@/view/components/Dialog";
import TextAreaInput from "@/view/components/TextAreaInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import FileUpload from "@/view/components/FileUpload";
import RadioInput from "@/view/components/RadioInput";
import PriceInput from "@/view/components/PriceInput";
import QuantityInput from "@/view/components/QuantityInput";
import { GetAllProduct } from "@/core/lib/common.lib";
import {
	ImportLineItems,
	UpdateOrCreateLineItems,
	DeleteLineItem,
	SearchLineItem,
	DeleteAllLineItems,
	GetLocalData,
	GetTempLineItems,
	// GETLINEITEMSSAMPLE,
} from "@/core/lib/quotation.lib";
import Draggable from "vuedraggable";
import { cloneDeep, filter, map, find, toNumber } from "lodash";
import { EventBus } from "@/core/event-bus/event.bus";
import "@/core/event-bus/click-outside";
import { saveData, getData } from "@/core/services/local.service";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { SET_ERROR } from "@/core/services/store/common.module";
export default {
	name: "quotation-line-items",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: Array,
			default() {
				return [];
			},
		},
		id: {
			type: Number,
			default: 0,
		},
		uuid: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			importDialog: false,
			confirmationDialog: false,
			backEndError: [],
			confirmationLoading: false,
			deleteConfirmationDialog: false,
			deleteConfirmationLoading: false,
			deletePayload: null,
			uploading: false,
			pageLoading: false,
			line_item_count: 0,
			parent_id: 0,
			type: "inventory",
			tax_applied: true,
			routeName: null,
			fileError: null,
			temSubTotal: null,
			exel_file_uuid: null,
			upload_excel: {
				type: null,
				file: null,
			},
			search_key: null,
			isOutside: true,
			isLineItems: false,
			showPrice: true,
			price: null,
			uom: null,
			old_items: {
				items: [],
				prouct_type: null,
				subtotal: 0,
			},
			oldItems: [],
			oldProuctType: null,
			oldSubTotal: 0,
			line_items: [
				{
					isEditable: false,
					id: null,
					uuid: null,
					product: null,
					product_type: null,
					product_barcode: null,
					description: null,
					quantity: 1,
					quantity_type: null,
					discount: null,
					rate: 0,
					uom: null,
					tax_value: null,
					tax_amount: null,
					sub_total: 0,
					total: 0,
					order: 0,
				},
			],
			tax: 9,
			timeout: null,
			timeoutLimit: 500,
			free_text: null,
			productList: [],
			old_exel_file_uuid: null,
			taxList: [7, 8, 9, 10, 11],
			tax_value: 9,
			taxable_amount: 0,
			tax_amount: 0,
			tax_type: 1,
			discount_value: 0,
			discount_amount: 0,
			discount_value_type: 1,
			discount_type: 1,
			adjustment: 0,
			adjustment_amount: 0,
			sub_total: 0,
			discountTypeList: [
				{ text: "$", value: 1 },
				{ text: "%", value: 2 },
			],
			orderLength: null,
			allProducts: [],

			showDiscountedRate: 0,
		};
	},
	watch: {
		value() {
			this.initComponent();
		},
		sub_total(value) {
			//console.log({sub_total:value});
			if (value == 0) {
				this.discount_value = 0;
				this.discount_amount = 0;
				this.tax_amount = 0;
				this.adjustment = 0;
				this.adjustment_amount = 0;
			}
		},
		uuid(param) {
			this.getTempLineItem(param);
		},
	},
	computed: {
		...mapGetters(["localDB"]),
		product_list: () => {
			return function (id) {
				let _productList = cloneDeep(this.productList);
				const newArray = filter(_productList, (row) => {
					if (row.value != id) {
						if (!find(this.line_items, (inner_row) => inner_row.product == row.id)) {
							return row;
						}
					} else {
						return row;
					}
				});
				return newArray;
			};
		},
		items() {
			return [
				{
					label: "Product",
					value: "inventory",
					disabled: this.upload_excel.type == "inventory" ? false : true,
				},
				{
					label: "Service",
					value: "service",
					disabled: this.upload_excel.type == "service" ? false : true,
				},
			];
		},
		min_rate: () => {
			return function (id) {
				let _productList = cloneDeep(this.productList);
				const newArray = filter(_productList, (row) => {
					if (row.value != id) {
						if (!find(this.line_items, (inner_row) => inner_row.product == row.id)) {
							return row;
						}
					} else {
						return row;
					}
				});
				return newArray;
			};
		},
	},
	methods: {
		showPriceCheckboxToggle() {
			if (this.showPrice) {
				this.sub_total = this.temSubTotal;
			}
		},
		changeUploadExcelType() {},
		orderChange() {
			//console.log({ param, line_items: this.line_items });
		},
		outsideClicked() {
			if (!this.isOutside) {
				const _line_items = map(this.line_items, (row) => {
					return { ...row, isEditable: false };
				});
				this.line_items = _line_items;
				this.isOutside = true;
			}
		},
		changeClicked() {
			this.isOutside = true;
		},
		editRow(event, index) {
			this.isOutside = false;
			let element = event.target;
			const _line_items = map(this.line_items, (row) => {
				return { ...row, isEditable: false };
			});
			this.line_items = _line_items;
			this.line_items[index].isEditable = true;
			this.$nextTick(() => {
				const inputarea = element.querySelector("input");
				const textarea = element.querySelector("textarea");
				//console.log({textarea:textarea,element:element})
				if (inputarea) {
					inputarea.focus();
					inputarea.select();
				}
				if (textarea) {
					textarea.focus();
					textarea.select();
				}
			});
			if (element.nodeName == "INPUT") {
				element.select();
			}
			//console.log(element.nodeName);
			if (element.nodeName == "TEXTAREA") {
				element.select();
			}
			// if (this.line_items.length == index + 1) {
			// 	this.addLineItems();
			// }
		},
		async searchLineItem() {
			const payload = {
				quotation: this.id,
				search: this.search_key,
				frontend_uuid: this.exel_file_uuid,
				/* parent: this.id ? this.id : null, */
				per_page: 100,
			};
			const { data } = await SearchLineItem(payload);
			this.line_items = data;
			//  console.log(data);
			// this.line_items = data
			// }
		},
		async clearSearchLineItem() {
			const payload = {
				quotation: this.id,
				search: null,
				frontend_uuid: this.exel_file_uuid,
				/* parent: this.id ? this.id : null, */
				per_page: 100,
			};
			const { data } = await SearchLineItem(payload);
			this.line_items = data;
		},
		async deleteLineItems(row, index) {
			const payload = {
				index: index,
				line_item: row.id,
				type: "quotation",
				app_frontend_uuid: this.exel_file_uuid,
				frontend_uuid: row.frontend_uuid ? row.frontend_uuid : null,
				parent: this.id ? this.id : 0,
			};
			// if (row.id && this.routeName == "quotation-update") {
			// 	this.deleteConfirmationDialog = true;
			// 	this.deletePayload = payload;
			// } else {
			try {
				console.log(row.id);
				if (row.id) {
					this.deleteConfirmationLoading = true;
					const { sub_total, line_item_count } = await DeleteLineItem(payload);
					if (this.showPrice) {
						this.sub_total = toNumber(sub_total);
						this.line_item_count = line_item_count;
					}
				}
				this.line_items.splice(index, 1);
				if (!this.line_items.length) {
					this.isLineItems = false;
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.deleteConfirmationLoading = false;
			}
			// }
		},
		async yesDeleteLineItem() {
			try {
				this.deleteConfirmationLoading = true;
				const { sub_total } = await DeleteLineItem(this.deletePayload);
				this.sub_total = toNumber(sub_total);
				this.line_items.splice(this.deletePayload.index, 1);
				if (!this.line_items.length) {
					this.isLineItems = false;
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.deleteConfirmationLoading = false;
				this.deleteConfirmationDialog = false;
			}
		},
		addLineItems() {
			const _order = this.orderLength ? this.orderLength : this.line_items.length;
			const _uuid = this.genrate_uuid();
			this.line_items.push({
				isEditable: false,
				id: null,
				uuid: _uuid,
				frontend_uuid: this.exel_file_uuid,
				product: null,
				product_type: null,
				product_barcode: null,
				description: null,
				quantity: 1,
				quantity_type: null,
				discount: null,
				rate: 0,
				uom: null,
				tax_value: null,
				tax_amount: null,
				sub_total: 0,
				total: 0,
				order: _order + 1,
			});
			this.$nextTick(() => {
				const scElement = document.querySelector(".quotation-line-items");
				scElement.scrollTo({ left: 0, top: scElement.scrollHeight, behavior: "smooth" });
			});
		},
		tabClickedQty(index) {
			if (!this.showPrice) {
				this.tabClicked(index);
			}
		},
		tabClicked(index) {
			if (this.line_items.length == index + 1) {
				this.addLineItems();
			}
			const _line_items = map(this.line_items, (row) => {
				return { ...row, isEditable: false };
			});
			this.line_items = _line_items;
			this.line_items[index + 1].isEditable = true;
		},
		async updateLineItemOnCheckboxChange() {
			for (let index = 0; index < this.line_items.length; index++) {
				const row = this.line_items[index];
				try {
					const payload = {
						frontend_uuid: row.frontend_uuid ? row.frontend_uuid : this.exel_file_uuid,
						id: row.id ? row.id : null,
						product: row.product ? row.product : 0,
						product_barcode: row.product_barcode ? row.product_barcode : null,
						uom: row.uom ? row.uom : null,
						description: row.description ? row.description : null,
						quantity: row.quantity ? row.quantity : 0,
						rate: row.rate ? row.rate : 0,
						sub_total: row.total ? row.total : 0,
						total: row.total ? row.total : 0,
						product_type: row.product_type ? row.product_type : null,
						order: row.order ? row.order : null,
						parent: this.parent_id ? this.parent_id : 0,
						type: "quotation",
						is_sheet_uploaded: this.is_sheet_uploaded ? this.is_sheet_uploaded : 0,
					};
					/* console.log({row}); */
					if (row.product) {
						let url = "";
						// if (this.routeName == "quotation-update" && row.id) {
						url = "line-item/update/temp";
						// } else {
						// 	url = "line-item/update/temp";
						// }

						const { item, sub_total, line_item_count } = await UpdateOrCreateLineItems(url, payload);
						if (!row.id) {
							this.line_items[index] = item;
						}
						// console.log(this.showPrice);
						if (this.showPrice) {
							this.sub_total = toNumber(sub_total);
							this.temSubTotal = toNumber(sub_total);
						}
						this.line_item_count = line_item_count;
						if (this.line_items.length) {
							this.isLineItems = true;
						}
					}
				} catch (error) {
					console.log(error);
				} finally {
					//console.log('first');
				}
			}
		},
		async updateLineItem(row, index) {
			try {
				const payload = {
					frontend_uuid: row.frontend_uuid ? row.frontend_uuid : this.exel_file_uuid,
					id: row.id ? row.id : null,
					product: row.product ? row.product : 0,
					product_barcode: row.product_barcode ? row.product_barcode : null,
					uom: row.uom ? row.uom : null,
					description: row.description ? row.description : null,
					quantity: row.quantity ? row.quantity : 0,
					rate: row.rate ? row.rate : 0,
					sub_total: row.total ? row.total : 0,
					total: row.total ? row.total : 0,
					product_type: row.product_type ? row.product_type : null,
					order: row.order ? row.order : null,
					parent: this.parent_id ? this.parent_id : 0,
					type: "quotation",
					is_sheet_uploaded: this.is_sheet_uploaded ? this.is_sheet_uploaded : 0,
				};
				/* console.log({row}); */
				if (row.product) {
					let url = "";
					// if (this.routeName == "quotation-update" && row.id) {
					url = "line-item/update/temp";
					// } else {
					// 	url = "line-item/update/temp";
					// }

					const { item, sub_total, line_item_count } = await UpdateOrCreateLineItems(url, payload);
					if (!row.id) {
						this.line_items[index] = item;
					}
					// console.log(this.showPrice);
					if (this.showPrice) {
						this.sub_total = toNumber(sub_total);
						this.temSubTotal = toNumber(sub_total);
					}
					this.line_item_count = line_item_count;
					if (this.line_items.length) {
						this.isLineItems = true;
					}
				}
			} catch (error) {
				console.log(error);
			} finally {
				//console.log('first');
			}
		},
		getMinRate(row, index, type) {
			const id = row.product;
			const _product = this.productList.find((row) => row.id == id);
			if (type == "show-rate") {
				this.showDiscountedRate = _product.discount_price;
			}
			if (type == "set-rate") {
				if (row.rate < _product.discount_price) {
					// this.$store.commit(SET_ERROR, [{ model: true, message: `Item minimum price is $${_product.discount_price}` }]);
					this.line_items[index].rate = _product.discount_price;
				}
			}
		},
		getProductTotal(row, index, type) {
			const id = row.product;
			const _product = this.productList.find((row) => row.id == id);
			if (type == "set-rate") {
				if (row.rate < _product.discount_price) {
					this.line_items[index].rate = _product.discount_price;
				}
			}
			const _subtotal = this.to_fixed(this.line_items[index].quantity * this.line_items[index].rate);
			this.line_items[index].total = _subtotal;
			this.line_items[index].sub_total = _subtotal;
			this.updateLineItem(row, index);
			//this.getSubTotal();
		},
		getProductName(id) {
			const product = this.productList.find((row) => row.value == id);
			if (product) {
				return product.text;
			} else {
				return null;
			}
		},
		getProductPrice(row, index) {
			const id = row.product;
			const _product = this.productList.find((row) => row.id == id);
			this.line_items[index].description = _product.description;
			this.line_items[index].product_barcode = _product.barcode;
			this.line_items[index].product_type = _product.product_type;

			this.line_items[index].uom = _product.uom ? String(_product.uom) : null;
			if (_product && _product.product_type == "inventory") {
				this.line_items[index].rate = _product.selling_cost;
				this.line_items[index].total = _product.selling_cost;
				this.line_items[index].sub_total = _product.selling_cost;
			}
			if (_product && _product.product_type == "service") {
				this.line_items[index].rate = _product.charges;
				this.line_items[index].total = _product.charges;
				this.line_items[index].sub_total = _product.charges;
			}
			this.updateLineItem(row, index);
			this.changeClicked();
		},
		fixedOnBlur() {
			const _line_item = map(this.line_items, (row) => {
				return {
					...row,
					rate: toNumber(row.rate),
					amount: toNumber(row.amount),
				};
			});
			this.line_items = _line_item;
		},
		updateTotal() {
			const _line_item = map(this.line_items, (row) => {
				return {
					...row,
					rate: this.to_fixed(row.amount / row.quantity),
				};
			});
			this.line_items = _line_item;
		},
		initComponent() {
			//const _this = this;
			//const result = cloneDeep(_this.value);
			//_this.line_items = result;

			this.getTempLineItem();
		},
		getAllProduct() {
			GetAllProduct()
				.then(({ list }) => {
					const _data = map(list, (row) => {
						return { ...row, value: row.id, text: `${row.barcode} - ${row.name}` };
					});
					this.allProducts = _data;
					if (this.upload_excel.type == "inventory") {
						this.productList = filter(_data, (row) => {
							return row.product_type == "inventory";
						});
					}
					if (this.upload_excel.type == "service") {
						this.productList = filter(_data, (row) => {
							return row.product_type == "service";
						});
					}

					// const _service_data = filter(list, (row) => {
					// 	return row.product_type == "inventory";
					// });
					// this.productList = map(_product_data, (row) => {
					// 	return { ...row, value: row.id, text: `${row.barcode} - ${row.name}` };
					// });
					// this.productList = list.map((row) => {
					// 	return { ...row, value: row.id, text: `${row.barcode} - ${row.name}` };
					// });
				})
				.catch((error) => {
					console.log(error);
				});
		},
		async clearLineItem() {
			this.type = this.upload_excel.type;
			if (this.upload_excel.type == "inventory") {
				this.productList = filter(this.allProducts, (row) => {
					return row.product_type == "inventory";
				});
			}
			if (this.upload_excel.type == "service") {
				this.productList = filter(this.allProducts, (row) => {
					return row.product_type == "service";
				});
			}
			const payload = {
				parent: this.id,
				module: "quotation",
			};
			try {
				this.confirmationLoading = true;
				if (this.line_items.length) {
					await DeleteAllLineItems(payload);
					this.exel_file_uuid = this.genrate_uuid();
					this.saveToLocal(this.exel_file_uuid);
					this.line_item_count = 0;
				}
				this.line_items = [];
				this.sub_total = 0;
				this.isLineItems = false;
				this.updateCalculation();
				this.$emit("hide:download", true);
			} catch (error) {
				console.log(error);
			} finally {
				this.confirmationLoading = false;
				this.confirmationDialog = false;
			}
		},
		closeConfirmation() {
			this.confirmationDialog = false;
			if (this.upload_excel.type == "inventory") {
				this.upload_excel.type = "service";
				this.productList = filter(this.allProducts, (row) => {
					return row.product_type == "service";
				});
			} else {
				this.upload_excel.type = "inventory";
				this.productList = filter(this.allProducts, (row) => {
					return row.product_type == "inventory";
				});
			}
		},
		updateProductType(item_type) {
			if (this.upload_excel.type != item_type) {
				this.confirmationDialog = true;
			}
		},
		validateNumber(value) {
			const pattern = /^(\d+(?:,\d{1,2})?).*/;
			return pattern.test(value);
		},
		getDiscount() {
			if (this.discount_value != 0) {
				if (!this.validateNumber(this.discount_value)) {
					this.discount_value = 0;
					this.discount_amount = 0;
					return false;
				}
			}
			const subtotal = toNumber(this.sub_total);
			if (this.discount_value_type == 1) {
				//$
				if (toNumber(this.discount_value) > toNumber(subtotal)) {
					this.discount_value = 0;
					this.discount_amount = 0;
					return false;
				}
				this.discount_amount = toNumber(this.discount_value);
			} else if (this.discount_value_type == 2) {
				//%
				if (toNumber(this.discount_value) > 100) {
					this.discount_value = 0;
					this.discount_amount = 0;
					return false;
				}
				this.discount_amount = toNumber((subtotal * this.discount_value) / 100);
			}
			return this.discount_amount;
		},
		getTax() {
			if (this.tax_applied) {
				const subtotal_taxable = toNumber(this.sub_total) - toNumber(this.discount_amount);
				this.taxable_amount = subtotal_taxable;
				this.tax_amount = toNumber((subtotal_taxable * this.tax_value) / 100);
			} else {
				this.tax_amount = 0;
			}
			return this.tax_amount;
		},
		getAdjustmentAmount() {
			this.adjustment_amount = toNumber(this.adjustment);
		},
		getGrandTotal() {
			this.getDiscount();
			this.total =
				toNumber(this.sub_total) +
				toNumber(this.tax_amount) +
				toNumber(this.adjustment) -
				toNumber(this.discount_amount);
			this.updateCalculation();
			return this.total;
		},
		updateCalculation() {
			this.$emit("update:total", {
				tax_value: toNumber(this.tax_value),
				taxable_amount: toNumber(this.taxable_amount),
				tax_amount: toNumber(this.tax_amount),
				tax_type: this.tax_type,
				discount_value: toNumber(this.discount_value),
				discount_value_type: this.discount_value_type,
				discount_amount: toNumber(this.discount_amount),
				discount_type: this.discount_type,
				adjustment: toNumber(this.adjustment),
				adjustment_amount: toNumber(this.adjustment_amount),
				sub_total: toNumber(this.sub_total),
				total: toNumber(this.total),
				show_price: this.showPrice ? 1 : 0,
				tax_applied: this.tax_applied ? 1 : 0,
				uom: this.uom,
				free_text: this.free_text,
				frontend_uuid: this.exel_file_uuid,
				isLineItems: this.isLineItems,
				line_item_type: this.upload_excel.type,
				is_sheet_uploaded: this.is_sheet_uploaded,
				line_item_count: this.line_item_count,
			});
			this.$emit("change", this.line_items);
		},
		async uploadLineItems() {
			this.backEndError = [];
			const _this = this;
			if (!_this.upload_excel.file[0].file) {
				this.fileError = "File is required";
				return false;
			} else {
				this.fileError = null;
			}
			//console.log(_this.upload_excel.file);
			_this.uploading = true;

			try {
				_this.old_exel_file_uuid = this.exel_file_uuid;
				_this.exel_file_uuid = this.genrate_uuid();
				_this.saveToLocal(_this.exel_file_uuid);
				const formData = new FormData();
				formData.append(`excel_file`, _this.upload_excel.file[0].file);
				formData.append(`data_type`, _this.type);
				formData.append(`type`, "quotation");
				formData.append(`frontend_uuid`, _this.exel_file_uuid);
				formData.append(`parent`, this.id ? this.id : 0);
				const { items, sub_total, line_item_count } = await ImportLineItems(formData);
				_this.line_items = items;
				_this.sub_total = toNumber(sub_total);
				_this.line_item_count = line_item_count;
				_this.is_sheet_uploaded = 1;
				if (_this.line_items.length) {
					_this.isLineItems = true;
				}
				this.getAllProduct();
				_this.importDialog = false;
			} catch (error) {
				_this.exel_file_uuid = this.old_exel_file_uuid;
				if (error?.data?.message?.excel_errros && error?.data?.message?.excel_errros.length) {
					this.backEndError = error?.data?.message?.excel_errros;
				} else if (error?.data?.excel_file) {
					_this.$store.commit(SET_ERROR, [{ model: true, message: error?.data?.excel_file[0] }]);
				} else {
					_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				}
			} finally {
				_this.uploading = false;
			}
		},
		openExcelUploadDialog() {
			const _this = this;
			_this.importDialog = true;
		},
		saveToLocal(id) {
			saveData("local_uuid", id);
		},
		async getQuotationSample() {
			const _this = this;
			try {
				_this.uploading = true;
				let endpoint = `export/line-items/quotation?type=${this.type}`;
				ApiService.setHeader();
				let downloadURL = process.env.VUE_APP_API_URL + endpoint;
				downloadURL = new URL(downloadURL);
				const token = JwtService.getToken();
				downloadURL.searchParams.append("token", token);
				window.open(downloadURL, "_blank");
			} catch (error) {
				console.log(error);
			} finally {
				_this.uploading = false;
			}
		},
		async getFromLocalLineItem() {
			const local_uuid = getData("local_uuid");
			if (local_uuid) {
				this.exel_file_uuid = local_uuid;
				const { data, sub_total, frontend_uuid, total_items, line_item_count } = await GetLocalData(
					local_uuid
				);
				this.line_items = [...this.line_items, ...data];
				this.sub_total = this.sub_total + sub_total;
				this.line_item_count = line_item_count;
				if (total_items) {
					this.isLineItems = true;
					this.exel_file_uuid = frontend_uuid;
				}
			}
		},
		async getTempLineItem() {
			if (this.uuid) {
				this.exel_file_uuid = this.genrate_uuid();
				const params = {
					frontend_uuid: this.exel_file_uuid,
				};
				const { line_items, subtotal, line_item_count } = await GetTempLineItems(this.uuid, params);
				this.line_items = line_items;
				this.sub_total = subtotal;
				this.temSubTotal = subtotal;
				this.line_item_count = line_item_count;
				//console.log(data);
			}
		},
	},
	components: {
		Draggable,
		Dialog,
		TextInput,
		TextAreaInput,
		AutoCompleteInput,
		TooltipQuestion,
		RadioInput,
		FileUpload,
		SelectUnit,
		PriceInput,
		QuantityInput,
	},
	beforeDestroy() {
		EventBus.$off("reload:calculation");
	},
	mounted() {
		const { name } = this.$route;
		this.routeName = name;
		this.tax_value = this.localDB("tax_value", 0);
		const result = cloneDeep(this.value);
		this.oldItems = result;
		if (result && !result.length) {
			//console.log("result");
			this.$emit("hide:download", true);
		}
		this.initComponent();

		this.exel_file_uuid = this.exel_file_uuid ? this.exel_file_uuid : this.genrate_uuid();
		this.saveToLocal(this.exel_file_uuid);

		// this.getTempLineItem();

		this.getAllProduct();
		EventBus.$on("reload:calculation", (params) => {
			console.log("params", params);
			this.tax_value = params.tax_value;
			this.taxable_amount = params.taxable_amount;
			this.tax_amount = params.tax_amount;
			this.tax_type = params.tax_type;
			this.discount_value = params.discount_value;
			this.discount_value_type = params.discount_value_type;
			this.discount_amount = params.discount_amount;
			this.discount_type = params.discount_type;
			this.adjustment = params.adjustment;
			this.adjustment_amount = params.adjustment;
			this.sub_total = toNumber(params.sub_total);
			this.total = params.total;
			this.showPrice = params.show_price ? true : false;
			this.tax_applied = params.tax_applied ? true : false;
			this.uom = params.uom;
			this.free_text = params.free_text;
			this.parent_id = params.parent_id;
			this.isLineItems = params.isLineItems;
			this.upload_excel.type = params.line_item_type;
			this.type = params.line_item_type;
			this.oldProuctType = params.line_item_type;
			this.oldSubTotal = toNumber(params.sub_total);
		});
	},
};
</script>
<style scoped>
.quotation-line-items th {
	font-weight: 500;
}

.quotation-line-items th,
.quotation-line-items td:not(.borer-none) {
	border-bottom: 1px solid #c7c7c7;
	border-left: 1px solid #c7c7c7;
	height: 40px;
}

.quotation-line-items th:first-child,
.quotation-line-items td:first-child {
	border-left: none !important;
}
.quotation-line-items {
	max-height: 500px;
	overflow: auto;
}

.quotation-line-items thead {
	position: sticky;
	top: 0;
	bottom: 0;
	z-index: 999999;
	background: #f6f6f6;
}
</style>
